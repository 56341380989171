import './styles/App.css';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    CardHeader, Divider, IconButton,
    List,
    ListItem, ListItemAvatar, ListItemText, Typography
} from "@mui/material";
import {
    ArrowDownward,
    PlayCircleOutline,
} from '@mui/icons-material';

import {useEffect, useState} from "react";
import FullScreenDialog from "./components/FullScreenDialog";

interface Section {
    name: string;
    title: string;
    contents: Lesson[];
}

interface Lesson {
    name: string;
    description: string;
}


function App() {
    const [content, setContent] = useState([]);
    const [selectedLesson, setSelectedLesson] = useState<Lesson>();
    const [selectedSection, setSelectedSection] = useState<Section>();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalClose = () => {
        setIsModalOpen(false);
    }
    const handleModalOpen = (lesson: Lesson, section: Section) => {
        setSelectedLesson(lesson);
        setSelectedSection(section);
        setIsModalOpen(true);
    }

    useEffect(() => {
        fetch('/manifest.json',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                setContent(data.sections);
            })

    }, [])

    return <Card sx={{minHeight: "100vh", padding: "1em 0.2em", background: "linear-gradient(rgb(255, 251, 227), rgb(75, 149, 181))"}}>
        <CardHeader title={"Introductory Course"}/>
        <CardContent>
            {isModalOpen && selectedLesson && <FullScreenDialog
                isOpen={isModalOpen}
                imageUrl={`/content/${selectedSection!.name}/${selectedLesson!.name}/image.png`}
                audioTranslationSRC={`/content/${selectedSection!.name}/${selectedLesson!.name}/audio_translation.mp3`}
                audioOriginalSRC={`/content/${selectedSection!.name}/${selectedLesson!.name}/audio_original.mp3`}
                textTranslation={`/content/${selectedSection!.name}/${selectedLesson!.name}/text_translation.txt`}
                textOriginal={`/content/${selectedSection!.name}/${selectedLesson!.name}/text_original.txt`}
                vocabulary={`/content/${selectedSection!.name}/${selectedLesson!.name}/vocabulary.csv`}
                title={selectedLesson!.name.split(".")[1]}
                onClose={handleModalClose}
                description={selectedLesson!.description}
            />}


            {content.map((section: any) => {
                // return section.name + " of " + content.length;
                return <Accordion >
                    <AccordionSummary
                        expandIcon={<ArrowDownward/>}

                    >
                        {/* stack content in div*/}
                        <div>
                            <Typography sx={{width: "100%"}} variant={"h6"} gutterBottom>
                                {section.name}
                            </Typography>
                            <Typography variant={"h5"} gutterBottom>
                                {section.title}
                            </Typography>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails>
                        <List sx={{width: '100%', background: "transparent"}}>
                            {section.contents.map((lesson: any) => {
                                return <>
                                    <ListItem alignItems={"flex-start"}>
                                        <ListItemAvatar>
                                            <IconButton onClick={() => handleModalOpen(lesson, section)}>
                                                <PlayCircleOutline/>
                                            </IconButton>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={lesson.name.split(".")[1]}
                                            secondary={lesson.description}>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                </>
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>
            })}
        </CardContent>
    </Card>;

}

export default App;