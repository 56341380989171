import React, {useCallback, useEffect, useState} from 'react';
import '../styles/FullScreenDialog.css';
import {Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Tab, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import TabPanel from '@mui/lab/TabPanel';
import {TabContext, TabList} from "@mui/lab";

interface FullScreenDialogProps {
    isOpen: boolean; // Prop to control modal visibility
    imageUrl: string; // Image source URL
    audioTranslationSRC: string; // Audio source URL
    audioOriginalSRC: string;
    textTranslation: string; // Text translation
    textOriginal: string; // Original text
    vocabulary: any;
    onClose: () => void; // Function to handle close button click
    title: string; // Title of the dialog
    description: string; // Description of the dialog
}

const FullScreenDialog: React.FC<FullScreenDialogProps> = (props: FullScreenDialogProps) => {

    const [selectedTab, setSelectedTab] = useState("3");
    const [audioSrc, setAudioSrc] = useState(props.audioTranslationSRC); // Or translationAudioUrl
    const [textFile, setTextFile] = useState(props.textTranslation); // Or translationTextFile
    const [transcript, setTranscript] = useState(""); // Or translationTextFile
    const [vocabulary, setVocabulary] = useState<string[][]>([]); // Or translationTextFile

    const handleChange = (newTab: string) => {
        setSelectedTab(newTab);
    };


    const fetchVocabulary = async () => {
        const response = await fetch(props.vocabulary);

        const text = await response.text();
        if (!text.includes("German")) {
            setVocabulary([]);
        } else {
            const rows = text.split("\n");
            const vocabulary = rows.map(row => row.split(","));
            setVocabulary(vocabulary);
        }
    }
    const fetchText = useCallback(async () => {
        const response = await fetch(textFile);
        const text = await response.text();
        setTranscript(text);
    }, [textFile])



    useEffect(() => {
        fetchText().then();
    }, [textFile, fetchText]);

    useEffect(() => {

        fetchText().then();
        fetchVocabulary().then();
    });


    return (
        <Dialog className={"modal"}
                style={{background: "linear-gradient(rgb(73, 50, 46), rgb(34, 53, 66))", backgroundColor: "blue"}}
                PaperProps={{sx: {background: "linear-gradient(rgb(255, 251, 227), rgb(75, 149, 181))"}}}
                open={props.isOpen} fullScreen={true}>
            <DialogTitle style={{
                paddingBottom: "0",
                margin: "1em",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <Typography variant={"h4"}>{props.title}</Typography>

            </DialogTitle>
            <DialogContent sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Typography align={"justify"} variant={"subtitle1"}>{props.description}</Typography>
                <img style={{width: "100%", marginBottom: "1em"}} src={props.imageUrl} alt="Modal dialog"/>

                <audio controls style={{width: "100%"}} src={audioSrc}/>
                <Button variant="text" style={{position: "absolute", top: "1em", right: "1em"}} onClick={props.onClose}>
                    <Close/>
                </Button>

                <TabContext value={selectedTab}>
                    <Box sx={{maxWidth: '100%'}}>
                        <TabList onChange={(event, value) => handleChange(value)}
                                 aria-label="audio-transcript-tabs">
                            <Tab label="Text" value="1"/>
                            {vocabulary.length > 0 ? <Tab label="Vocab" value={"2"}/> : null}
                            <Tab label="Sprache" value={"3"}/>
                        </TabList>
                    </Box>

                    <TabPanel value={"1"} style={{borderRight: "1px solid white"}}>
                        <ButtonGroup>
                            <Button size={"small"} variant={textFile === props.textOriginal ? "outlined" : "contained"}
                                    onClick={() => setTextFile(props.textTranslation)}>Deutsch</Button>
                            <Button size={"small"} variant={textFile === props.textOriginal ? "contained" : "outlined"}
                                    onClick={() => setTextFile(props.textOriginal)}>Englisch</Button>
                        </ButtonGroup>
                        {transcript.split("\n").map((line, index) => {
                            return <p key={index}>{line}</p>
                        })}
                    </TabPanel>
                    {vocabulary.length > 0 ?
                        <TabPanel value={"2"} style={{borderRight: "1px solid white"}}>
                            {/*    first row of the vocab is the header*/}
                            <table>
                                <thead>
                                <tr>
                                    <th>{vocabulary[0][0]}</th>
                                    <th>{vocabulary[0][1]}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {vocabulary.slice(1).map((row, index) => {
                                    return <tr key={index}>
                                        <td>{row[0]}</td>
                                        <td>{row[1]}</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </TabPanel> : null}
                    <TabPanel value={"3"}>
                        <ButtonGroup>
                            <Button size={"small"}
                                    variant={audioSrc === props.audioTranslationSRC ? "contained" : "outlined"}
                                    onClick={() => setAudioSrc(props.audioTranslationSRC)}>Deutsch</Button>
                            <Button size={"small"}
                                    variant={audioSrc === props.audioTranslationSRC ? "outlined" : "contained"}
                                    onClick={() => setAudioSrc(props.audioOriginalSRC)}>Englisch</Button>
                        </ButtonGroup>
                    </TabPanel>
                </TabContext>

            </DialogContent>

        </Dialog>
    );
};

export default FullScreenDialog;
